<template>
  <v-date-picker class="inline-block h-full" v-model="date" :masks="masks">
    <template v-slot="{ inputValue, togglePopover }">
      <div class="input-group">
        <input type="text" class="form-control datepickerinput" :value="inputValue" readonly @click="togglePopover()" />
        <span class="input-group-text" @click="togglePopover()"><font-awesome-icon :icon="['fas', 'calendar-day']" /></span>
      </div>

    </template>
  </v-date-picker>
</template>

<script>
import { DatePicker as VDatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';

export default {
  name: 'DatePicker',
  props: {

  },
  components: {
    VDatePicker,
  },
  data() {
    return {
      date: new Date(),
      masks: {
        input: 'YYYY-MM-DD',
      },
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
